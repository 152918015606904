/* src/pages/BookSession/BookSession.css */
.booking-card {
    max-width: 800px;
    margin: 0 auto;
    border: none;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .booking-form {
    padding: 20px;
  }
  
  .booking-form h2 {
    color: #333;
    margin-bottom: 30px;
    font-weight: 700;
  }
  
  .booking-form .form-label {
    font-weight: 600;
    color: #555;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .booking-form .form-control,
  .booking-form .form-select {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    padding: 12px 15px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .booking-form .form-control:focus,
  .booking-form .form-select:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .booking-form textarea {
    resize: vertical;
  }
  
  .booking-form button[type="submit"] {
    padding: 12px 20px;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    background-color: #007bff;
    border: none;
  }
  
  .booking-form button[type="submit"]:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
    background-color: #0056b3;
  }
  
  .alert {
    border-radius: 8px;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .booking-card {
      margin: 0 15px;
    }
  }