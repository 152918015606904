/* src/pages/Home/Home.css */
.hero-section {
  position: relative;
  color: white;
  padding: 100px 0;
  height: 100vh;
  text-align: center;
  background: none;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/src/Components/images/bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0.7);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(58, 38, 67, 0.8) 0%, rgba(118, 75, 162, 0.881) 100%);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.text-gradient {
  background: linear-gradient(to right, #d2e6ff, #27ffc5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

.red-gradient {
  background: linear-gradient(to right, #fff891cf, #ff5227e5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.custom-button {
  position: relative;
  padding: 15px 35px;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.custom-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
  );
  transition: all 0.5s ease;
}

.custom-button:hover::before {
  left: 100%;
}

.custom-button:hover {
  transform: translateY(-3px);
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.custom-button.primary {
  background: linear-gradient(
      45deg,
      rgba(130, 207, 255, 0.699),
      rgba(127, 97, 249, 0.2)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgb(255, 255, 255);
}

.custom-button.secondary {
  background: linear-gradient(
      45deg,
      rgba(255, 130, 249, 0.678),
      rgba(131, 29, 154, 0.653)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgb(255, 255, 255);

}

.button-icon {
  margin-left: 8px;
  vertical-align: middle;
}