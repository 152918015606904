/* src/components/Header/Header.css */
.custom-navbar {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    padding: 1rem 2rem;
  }
  
  .custom-nav-link {
    color: white !important;
    margin: 0 1rem;
    position: relative;
  }
  
  .custom-nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transition: width 0.3s ease;
  }
  
  .custom-nav-link:hover::after {
    width: 100%;
  }